const analyticsParams = {
  gaId4: 'G-13ZBBHLPNS',
  category: "Clicks",
  events: {
    MENU_SHOW: {
      action: [ "Menu" ],
    },
    GOOGLEPLAY_OPEN: {
      action: [ "GooglePlay" ],
      label: [ "id" ],
    },
    AMAZON_OPEN: {
      action: [ "Amazon" ],
      label: [ "id" ],
    },
    HUAWEI_OPEN: {
      action: [ "Huawei" ],
      label: [ "id" ],
    },
    DOWNLOAD_CANCEL: {
      action: [ "DownloadCancel" ],
      label: [ "id" ],
    },
    DOWNLOAD_CONFIRM: {
      action: [ "DownloadConfirm" ],
      label: [ "id" ],
    },
    GAME_SOURCE: {
      action: [ "GameSource" ],
      label: [ "id" ],
    },
    GAME_ICON_CLICK: {
      action: [ "GameIcon" ],
      label: [ "id" ],
    },
    SCREENS_SCROLL: {
      action: [ "ScrollScreens" ],
      label: [ "id" ],
    },
    PAGE_SCROLL: {
      action: [ "ScrollPage" ],
    },
    APP_CLICK: {
      action: [ 'App' ],
      label: [ 'id'],
    },
    COOKIES_POLICY_SHOW: {
      action: [ "ShowCookies" ],
      category: "Policy",
    },
    COOKIES_POLICY_ACCEPT: {
      action: [ "AcceptCookie" ],
      category: "Policy",
    },
    DOWNLOAD_SHOW: {
      action: [ "show_download" ],
      label: [ "id" ],
      conversion: ["all_in"],
    },
    RELATED_CLICK: {
      action: [ "RelatedPosition", "related_click" ],
      label: [ "position", "id" ],
      conversion: ["all_in", "related_click"],
    },
    PRELAND_CLICKED: {
      action: ['first_click'],
      category: "Index",
      conversion: ["all_in"],
    },
    INTERSTITIAL_AD_SHOW: {
      action: [ "content_loaded" ],
      label: [ "label" ],
      category: "Index",
      conversion: ["content_direct"],
    },
    APPTOP_LOADED: {
      action: [ "apptop_loaded" ],
    },
    CL_APPTOP_LOADED: {
      action: [ "cl_apptop_loaded" ],
      conversion: ["cl_apptop_direct"],
    },
    DOWNTOP_LOADED: {
      action: [ "downtop_loaded" ],
      conversion: ["downtop_direct"],
    },
    CL_DOWNTOP_LOADED: {
      action: [ "cl_downtop_loaded" ],
      conversion: ["cl_downtop_direct"],
    },
    DOUBLE_LOADED: {
      action: [ "double_loaded" ],
      conversion: ["double_direct"],
    },
    CL_DOUBLE_LOADED: {
      action: [ "cl_double_loaded" ],
      conversion: ["cl_double_direct"],
    },
    DOUBLE_DOUBLE: {
      action: [ "double_double" ],
    },
  }
}

export default analyticsParams;
