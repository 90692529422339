import React, { useEffect, useState, useRef } from "react";
import ajaxGet from "../utils/ajaxGet";
import getCookie from "../utils/getCookie.js";
import setCookie from "../utils/setCookie.js";
import styled from "styled-components";
import { useStoreon } from "storeon/react";
import PageLoader from './PageLoader';
import { breakpoint } from './theme';

import sendAnalyticsEvent from "../utils/sendAnalyticsEvent";

import RelatedBlock from "./RelatedBlock";
import Adv from "./Adv";
import Thumb from "./Thumb";
import Info from "./Info";
import ModalDownload from "./ModalDownload";
import ModalReward from "./ModalReward";
import Loader from './Loader';

import setMeta from '../utils/setMeta';
import setPageKeys from '../utils/setPageKeys';
import checkHeight from '../utils/checkHeight';
import getJsonPath from '../utils/getJsonPath';

const Page = styled.div`
  max-width: 1024px;
  margin: 0 auto;
`;

const PageTitle = styled.h1`
  font-size: 18px;
  font-weight: 500;
  margin: 21px 10px 9px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${breakpoint.tabletUp} {
    margin: 13px 8px 15px;
  }
`;

const Head = styled.div`
  display: flex;
  padding: 14px 10px 6px;
  align-items: center;

  ${breakpoint.tabletUp} {
    padding: 12px 8px 6px;
    align-items: flex-start;
  }
`;

const ThumbStyled = styled(Thumb)`
  ${breakpoint.tabletUp} {
    border-radius: 8px;
  }
`;

const SubTitle = styled.h2`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 3px;
  ${breakpoint.tabletUp} {
    margin-right: 67px;
  }
`;

const ColLeft = styled.div`
  flex: 56px 0 0;

  ${breakpoint.tabletUp} {
    flex-basis: 88px;
  }
`;

const ColRight = styled.div`
  padding-left: 12px;
  padding-bottom: 1px;

  ${breakpoint.tabletUp} {
    padding-top: 8px;
    padding-left: 13px;
    width: 100%;
  }
`;

const ColRightCnt = styled.div`
  ${breakpoint.tabletUp} {
    display: flex;
    margin-bottom: 7px;
  }
`;

const SourceList = styled.div`
  margin: 0 20px;

  ${breakpoint.tabletUp} {
    display: none;
  }
`;

const Source = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #D7DFE9;

  &:last-child {
    border-bottom: 0;
  }

  ${breakpoint.tabletUp} {
    border-bottom: 0;
    flex: 50% 0 0;
    justify-content: flex-start;
    padding-left: 8px;
  }
`;

const ColRightSrc = styled.div`
  display: none;

  ${breakpoint.tabletUp} {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

const SourceCnt = styled.div`
  letter-spacing: 0.03em;

  ${breakpoint.tabletUp} {
    text-align: right;
  }
`;

const StoreName = styled.h2`
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.5em;
  margin-bottom: 2px;

  ${breakpoint.tabletUp} {
    margin-top: 2px;
  }
`;

const StoreInfo = styled.div`
  font-size: 12px;
  font-weight: 500;
`;

const Button = styled.button`
  background: linear-gradient(92.86deg, #FFA21F 0%, #FF5517 44.89%, #FF5618 54.61%, #FFA21F 100%);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  height: 40px;
  width: 170px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  font-family: inherit;
  position: relative;

  ${breakpoint.tabletUp} {
    margin-left: 14px;
  }
`;

const ButtonLoader = styled(Loader)`
  display: ${props => props.showLoader ? null : 'none'};
  margin: 0;
  height: 22px;
  width: 22px;
  border-width: 2px;
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -11px;
  left: auto;
`;

const Block = styled.div`
  position: relative;
`;

const AdvSecond = styled(Adv)`
  padding-top: 20px;
`;

const PageDownload = ({ slug }) => {
  const { dispatch, appPage } = useStoreon('appPage');
  const { ads } = useStoreon('ads');
  const [ modalHref, setModalHref ] = useState(' ');
  const [ modalShown, setModalShown ] = useState(false);
  const [ modalRewShown, setModalRewShown ] = useState(false);
  const [ showLoader, setShowLoader] = useState(false);
  const [ timeout ] = useState(1500);


  const [ pageFetching, setPageFetching ] = useState(false);

  const [ selectedTab, setSelectedTab ] = useState(0);
  const [ gallerySlug, setGallerySlug ] = useState(slug);
  const [folded, setFolded] = useState(false);

  const isFetching = useRef(false);
  const currentSlug = useRef(slug);
  const count = useRef(appPage ? appPage.related.length : 0);

  // useEffect(() => {
  //   if (!appPage) {
  //     ajaxGet(`${window.location.href.slice(0, -10)}.json`)
  //       .then(data => {
  //         console.log(data.appPage);
  //         dispatch('appPage/success', data.appPage);
  //       })
  //       .catch(error => {
  //         dispatch('appPage/fail');
  //       });
  //   } else {
  //     window.scrollTo(0, 0);
  //   }

  //   sendAnalyticsEvent('DOWNLOAD_SHOW', { id: appPage.id });
  // }, [slug]);
  useEffect(() => {
    document.dispatchEvent(new Event('page_render'));

    if (appPage && appPage.id === +slug) {
      setMeta(appPage.pageTitle, appPage.pageDescription, appPage.keywords);
      setPageKeys(appPage.pageKeys);
      checkHeight(loadingElements);
    } else {
      setPageFetching(true);
      window.scrollTo(0, 0);
      ajaxGet(getJsonPath())
        .then(data => {
          setSelectedTab(0);
          count.current = data.appPage.related.length;
          dispatch('appPage/success', data.appPage);
          setMeta(data.appPage.pageTitle, data.appPage.pageDescription, data.appPage.keywords);
          setPageKeys(data.appPage.pageKeys);
          setGallerySlug(slug);
          currentSlug.current = slug;
          setPageFetching(false);
          setTimeout(() => checkHeight(loadingElements), 0);
        })
        .catch(error => {
          dispatch('appPage/fail');
        });
    }
  }, [slug]);

  useEffect(() => {
    return () => {
      if(window.googletag && window.googletag.pubads) {
        window.googletag.pubads().clear();
      }
    }
  }, []);

  const loadingElements = () => {
    isFetching.current = true;
    setShowLoader(true);
    const { pathname } = new URL(window.location.origin + '/data/thumbs/apps/' + currentSlug.current);

    ajaxGet(`${pathname}?offset=${count.current}&limit=12`)
      .then(data => {
        isFetching.current = false;
        setShowLoader(false);
        count.current = count.current + data.related.length;
        dispatch('appPage/add', data.related);
      })
      .catch(error => {
        dispatch('appPage/fail');
      });
  };

  const handleDoubleSlotView = () => {
    sendAnalyticsEvent('DOUBLE_LOADED');
    if (getCookie('shownInterstitial'))
      sendAnalyticsEvent('CL_DOUBLE_LOADED');
  }

  useEffect(() => {

    document.dispatchEvent(new Event('prepareManualInterstitial_test'));
    document.addEventListener('double_slot_view', handleDoubleSlotView);
    document.addEventListener('reward_granted', rewardHandler);
    return () => {
      document.removeEventListener('double_slot_view', handleDoubleSlotView);
      document.removeEventListener('reward_granted', rewardHandler);
    };
  }, []);

  const handleRewCancel = () => {
    console.log('Reward disabled');
    setModalRewShown(false);
    setModalShown(true);
  }

  const handleRewConfirm = () => {
    console.log('Reward confirmd');
    setModalRewShown(false);
    document.dispatchEvent(new Event('showManualInterstitial_test'));
    setTimeout(() => {
      setModalShown(true);
    }, 4000)
  }

  const rewardHandler = () => {
    setModalShown(true);
    setShowLoader(false);

    if(window && window.reward_required) {
      setCookie('rewarded', 1, 0.0834);
    }

    console.log('reward worked');
  }

  const sourceClickHandler = (sourceName, href) => {
    if(showLoader) {
      sendAnalyticsEvent('DOUBLE_DOUBLE');
      return;
    }

    setModalHref(href);
    setShowLoader(true);

    if(window && window.reward_required) {
      setModalRewShown(true);
      return;
    }

    document.dispatchEvent(new Event('prepareManualInterstitial'));
    document.dispatchEvent(new Event('showManualInterstitial_test'));

    setTimeout(() => {
      document.dispatchEvent(new Event('showManualInterstitial'));
    }, 600)

    if(!ads.slots['reward']) {
      setTimeout(() => {
        if (!modalShown) {
          setModalShown(true);
          setShowLoader(false);
          document.dispatchEvent(new Event('showManualInterstitial_leg'));

          let eventName;

          switch (sourceName) {
            case 'Google Play': eventName = 'GOOGLEPLAY_OPEN'
              break;

            case 'Amazon': eventName = 'AMAZON_OPEN';
              break;

            case 'Huawei': eventName = 'HUAWEI_OPEN'
              break;

            default:
              console.error('unknown sourceName');
          }

          if (eventName) sendAnalyticsEvent(eventName, { id: appPage.id });
        }
      }, timeout);
    }
  };

  const handleCancel = () => {
    sendAnalyticsEvent('DOWNLOAD_CANCEL', { id: appPage.id });
    setModalShown(false);
    setTimeout(() => location.reload(), 100);
  };

  const handleConfirm = () => {
    sendAnalyticsEvent('DOWNLOAD_CONFIRM', { id: appPage.id });
    setModalShown(false);
  }

  return (
    <>
      {appPage && <Page>
        <PageTitle>{appPage.title} - Download Sources</PageTitle>
        {ads.slots['ztop-download-ATF'] && <Adv
          slot={ads.slots['ztop-download-ATF']}
          analyticsAction="DOWNTOP_LOADED"
          analyticsActionSpecial="CL_DOWNTOP_LOADED"
        />}
        <Block>
          <Head>
            <ColLeft><ThumbStyled image={appPage.image} sizeMobile={56} sizeTablet={88} /></ColLeft>
            <ColRight>
              <ColRightCnt>
                <SubTitle>{appPage.title} / Game</SubTitle>
                <Info {...appPage.info} />
              </ColRightCnt>
              <ColRightSrc>
                {appPage.sources && appPage.sources.map((item, key) => (
                  <Source key={key}>
                    <SourceCnt>
                      <StoreName>{item.title}</StoreName>
                      <StoreInfo>{item.price} / {item.size}</StoreInfo>
                    </SourceCnt>
                    <Button onClick={() => sourceClickHandler(item.title, item.link)}
                    >Download<ButtonLoader color="white" showLoader={showLoader}/></Button>
                  </Source>
                ))}
              </ColRightSrc>
            </ColRight>
          </Head>
          {appPage.sources && <SourceList>
            {appPage.sources.map((item, key) => (
              <Source key={key}>
                <SourceCnt>
                  <StoreName>{item.title}</StoreName>
                  <StoreInfo>{item.price} / {item.size}</StoreInfo>
                </SourceCnt>
                <Button onClick={() => sourceClickHandler(item.title, item.link)}
                >Download<ButtonLoader color="white" showLoader={showLoader}/></Button>
              </Source>
            ))}
          </SourceList>}
        </Block>
        {ads.slots['ztop-download-BTF'] && <AdvSecond slot={ads.slots['ztop-download-BTF']} minHeight={100} />}
        {appPage.related && <RelatedBlock related={appPage.related} adPosition={5} adName="ztop-download-LIST" />}
        <ModalDownload shown={modalShown} href={modalHref} onCancel={handleCancel} onConfirm={handleConfirm} />
        <ModalReward shown={modalRewShown} onCancel={handleRewCancel} onConfirm={handleRewConfirm} />
      </Page>}
      {!appPage && <PageLoader/>}
    </>
  )
};

export default PageDownload;
